import { DataGrid } from "@mui/x-data-grid";
import { Container, Grid, Hidden, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ThemeCard from "./ThemeCard";
import ThemeFilter from "./ThemeFilter";
import { fontSize } from "@mui/system";

// const columns = [
//   { field: "id", headerName: "ID", width: 70 },
//   { field: "title", headerName: "Názov témy", width: 300 },
//   { field: "appuser_id", headerName: "Školiteľ", width: 130 },
//   {
//     field: "degree",
//     headerName: "Stupeň",
//     width: 90,
//   },
//   {
//     field: "field",
//     headerName: "Zameranie",
//     width: 160,
//   },
// ];

const ThemeList = (props) => {
  const [rows, setRows] = useState([]);

  const [nameFilter, setNameFilter] = useState("");
  const [authorFilter, setAuthorFilter] = useState("");
  const [stupenFilter, setStupenFilter] = useState("bakalárinžinierdoktorand");

  useEffect(() => {
    const requestData = async () => {
      var url = "/api/getThemeList";
      if (props.themeList === "myThemes") url = "/api/getMyThemes";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          token: props.token,
          user: props.user,
        }),
      });
      const parsed = await response.json();
      const mapped = parsed.map((r) => {
        return {
          id: r.thesis_id,
          title: r.title,
          appuser_id: r.thesis_appuser.name,
          degree:
            r.degree === "b"
              ? "bakalár"
              : r.degree === "i"
              ? "inžinier"
              : "doktorand",
          field: r.thesis_fields.map((tf) => tf.field.name).join(", "),
          subject: r.subject,
          email: r.thesis_appuser.email,
          upn: r.thesis_appuser.upn,
        };
      });
      setRows(mapped);
    };
    requestData();
  }, [props.themeList]);

  const handleRowClick = (r, appuser_id) => {
    console.log(r);
    props.showThemeDetails(r, appuser_id);
  };

  const rowFiltered = rows.filter(
    (r) =>
      r.title.toLowerCase().includes(nameFilter.toLowerCase()) &&
      r.appuser_id.toLowerCase().includes(authorFilter.toLowerCase()) &&
      stupenFilter.includes(r.degree)
  );

  return (
    // <Container>
    <Grid
      container
      justifyContent="center"
      spacing={0}
      direction="row"
      marginLeft="auto"
      alignContent="center"
      style={{ marginLeft: "auto" }}
    >
      <Grid item xs={2} />
      <Grid item xs={6}>
        <Typography
          variant="h5"
          noWrap
          component="div"
          marginBottom="50px"
          style={{
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            textAlign: "left",
            fontSize: "2em",
          }}
        >
          Návrhy tém záverečných prác
        </Typography>
      </Grid>
      <Grid item xs={4} />

      <Hidden xlDown>
      <Grid item xs={2} />
      <Grid item xs={6} style={{ alignItems: "left", marginLeft: "auto" }}>
        {rowFiltered.map((r) => (
          <ThemeCard
            key={r.id}
            id={r.id}
            data={r}
            // showDetails={() => {
            //   handleRowClick(r, r.appuser_id);
            // }}
          />
        ))}
      </Grid>
      <Grid item xs={4} style={{ paddingTop: "30px", paddingLeft: "50px" }}>
        <ThemeFilter
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          authorFilter={authorFilter}
          setAuthorFilter={setAuthorFilter}
          stupenFilter={stupenFilter}
          setStupenFilter={setStupenFilter}
          showFilter={true}
        />
      </Grid>
      </Hidden>

      <Hidden xlUp>
      <Grid item xs={12} style={{ paddingTop: "30px", marginLeft: "50px" }}>
        <ThemeFilter
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          authorFilter={authorFilter}
          setAuthorFilter={setAuthorFilter}
          stupenFilter={stupenFilter}
          setStupenFilter={setStupenFilter}
          showFilter={false}
        />
      </Grid>
      <Grid item xs={10} style={{ alignItems: "left" }}>
        {rowFiltered.map((r) => (
          <ThemeCard
            key={r.id}
            id={r.id}
            data={r}
            // showDetails={() => {
            //   handleRowClick(r, r.appuser_id);
            // }}
          />
        ))}
      </Grid>
      </Hidden>
    </Grid>
    // </Container>

    // <Container maxWidth="sx" style={{ height: "40rem" }}>

    //   {/* <ThemeCard /> */}
    //   {/* <DataGrid
    //     onRowClick={handleRowClick}
    //     rows={rows}
    //     columns={columns}
    //     pageSize={10}
    //     rowsPerPageOptions={[5]}
    //     // checkboxSelection
    //   /> */}
    // </Container>
  );
};

export default ThemeList;
