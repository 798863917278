import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { borderRadius } from "@mui/system";
import { Link } from "react-router-dom";

import classes from "./ThemeCard.module.css";
import zIndex from "@mui/material/styles/zIndex";

const {
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} = require("@mui/material");

const ThemeCard = (props) => {
  const title = props.data.title;
  const author = props.data.appuser_id;
  const field = props.data.field;
  const degree = props.data.degree;
  const subject = EditorState.createWithContent(
    stateFromHTML(props.data.subject)
  );

  return (
    <Box
      sx={{
        minWidth: 275,
        padding: 1,
        margin: 1.5,
        maxWidth: "100%",
        boxShadow: "0 1.5px 12px rgba(0, 0, 0, 0.25)",
        borderRadius: 2,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Card variant="outlined" style={{ marginLeft: "auto" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {author}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            // onClick={props.showDetails}
            style={{ cursor: "pointer" }}
          >
            <Link style={{ textDecoration: "none", color: "inherit"}}
              to={{
                pathname: "/themeDetail",
                search: `?theme=${props.id}`,
              }}
            >
              {title}
            </Link>
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {degree}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {field}
          </Typography>
          <Typography
            variant="body2"
            style={{
              maxHeight: "6em",
              overflow: "hidden",
              textOverflow: "elypsis",
            }}
          >
            <Editor editorState={subject} readOnly toolbarHidden />
          </Typography>
          {/* <Typography variant="div" style={{ height: "4em", position: "absolute" , backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)", zIndex: 1, width: "100%", content: ""  }}  /> */}
        </CardContent>
        <CardActions>
          <Button size="small">
          <Link style={{ textDecoration: "none", color: "inherit"}}
              to={{
                pathname: "/themeDetail",
                search: `?theme=${props.id}`,
              }}
            >
              Viac...
            </Link>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ThemeCard;
