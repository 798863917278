import Grid from "@mui/material/Grid";
import { FormControl, Input, InputLabel } from "@mui/material";
import Button from '@mui/material/Button';

//import axios from "axios";
import {useState} from "react";

const marginStyle={
  marginBottom: "20px"
}

const inputHeight={
  minHeight: "30px"
}

const Login = (props) => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const onLoginValueChanged = (e) => {
        setLogin(e.target.value);
    }

    const onPasswordValueChanged = (e) => {
        setPassword(e.target.value);
    }


    const onLogin = async (e) => {
        e.preventDefault();

        // const response = await axios.post("http://localhost:5000/api/login", { login: login, password: password });
        const response = await fetch("/api/login", {
            crossDomain:true,
            method: "POST",
            body: JSON.stringify({
                username: login,
                password: password
            }),
            headers: {
                "Content-type": "application/json"
            }
        });
        const parsed = await response.json();
        props.onLogin(parsed.user, parsed.token);
    }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ paddingTop: "10%" }}
    >
      <Grid item xs={3}>
        <form onSubmit={onLogin}>
          <FormControl style={marginStyle}>
            <InputLabel htmlFor="ldap_login">Zadajte váš ldap login</InputLabel>
            <Input id="ldap_login" type="text" required={true} value={login} onChange={onLoginValueChanged} style={inputHeight} />
          </FormControl>
          <br />
          <FormControl style={marginStyle}>
            <InputLabel htmlFor="ldap_password">Heslo</InputLabel>
            <Input id="ldap_password" type="password" required={true} value={password} onChange={onPasswordValueChanged} style={inputHeight} />
          </FormControl>
          <br />
          <Button type="submit" variant="outlined">Prihlásiť</Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
