import { useState, useEffect, Fragment } from "react";
import Button from "@mui/material/Button";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { minWidth } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";

const ThesisDetail = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [thesisId, setThesisId] = useState(null);
  const [theme, setTheme] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState("");
  const [degree, setDegree] = useState("b");
  const [visible, setVisible] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(
    !props.user || props.user.role !== "ucitelia" || props.thesis_id
  );
  const [themeOwner, setThemeOwner] = useState({});

  const onEditorStateChange = (editorState) => {
    setTheme(editorState);
  };

  const saveHandler = async () => {
    const response = await fetch("/api/saveTheme", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        token: props.token,
        thesis_id: thesisId,
        title: title,
        subject: stateToHTML(theme.getCurrentContent()),
        degree: degree,
        visible: visible,
      }),
    });
    // console.log(response);
  };

  useEffect(() => {
    const thesis_id = searchParams.get("theme");

    if (thesis_id) {
      const fetchData = async () => {
        const response = await fetch("/api/getThemeDetail", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            thesis_id: thesis_id,
          }),
        });
        const parsed = await response.json();
        setTheme(EditorState.createWithContent(stateFromHTML(parsed.subject)));
        setTitle(parsed.title);
        setDegree(parsed.degree);
        setVisible(parsed.visible);
        setThesisId(parsed.thesis_id);
        setIsReadOnly(
          !props.user ||
            props.user.role !== "ucitelia" ||
            props.user.appuser_id !== parsed.appuser_id
        );
        setThemeOwner(parsed.thesis_appuser);
      };
      fetchData();
    }
  }, []);

  const onTitleChanged = (e) => {
    setTitle(e.target.value);
  };

  const onDegreeChanged = (e) => {
    setDegree(e.target.value);
  };

  const onVisibleChange = (e) => {
    setVisible(e.target.checked);
  };

  if (isReadOnly) {
    // console.log(props);
  }

  const message = encodeURI(
    `Dobrý deň, ${themeOwner.name}, chcem sa informovať o téme ${title}.`
  );

  return (
    <Container maxWidth="sx" style={{ borderColor: "#bfbfbf", borderWidth: 1 }}>
      {!isReadOnly && (
        <Fragment>
          <FormControl style={{ marginBottom: "20px", width: "50%" }}>
            <InputLabel htmlFor="title">Zadajte názov témy</InputLabel>
            <Input
              id="title"
              type="text"
              required={true}
              value={title}
              onChange={onTitleChanged}
            />
          </FormControl>
          <br />
          <FormControl>
            <InputLabel htmlFor="degree">Stupeň</InputLabel>
            <Select
              id="degree"
              value={degree}
              onChange={onDegreeChanged}
              sx={{
                marginBottom: "20px",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
            >
              <MenuItem value="b">Bakalár</MenuItem>
              <MenuItem value="i">Inžinier</MenuItem>
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={visible} onChange={onVisibleChange} />
              }
              label="Zobraziť zadanie pre študentov"
            />
          </FormGroup>
        </Fragment>
      )}
      {isReadOnly && (
        <Fragment>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="h6" component="div">
            {themeOwner.name}
          </Typography>
          <Button
            variant="outlined"
            //onClick={props.backHandler}
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={{
                pathname: "/",
              }}
            >
              Späť
            </Link>
          </Button>
          <Button
            variant="outlined"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `https://teams.microsoft.com/l/chat/0/0?users=${themeOwner.upn}@uniza.sk,${themeOwner.upn}@fri.uniza.sk,${themeOwner.upn}@stud.uniza.sk&message=${message}`;
            }}
            // component="a"
            // href={`https://teams.microsoft.com/l/chat/0/0?users=${props.theme.upn}@uniza.sk,${props.theme.upn}@fri.uniza.sk,${props.theme.upn}@stud.uniza.sk&message=${message}`}
            style={{ margin: "1em" }}
          >
            Mám záujem o tému
          </Button>
        </Fragment>
      )}
      <Editor
        editorState={theme}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        readOnly={isReadOnly}
        toolbarHidden={isReadOnly}
      />
      <Button
        variant="outlined"
        //onClick={props.backHandler}
      >
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={{
            pathname: "/",
          }}
        >
          Späť
        </Link>
      </Button>
      {!isReadOnly && (
        <Button variant="outlined" onClick={saveHandler}>
          Save
        </Button>
      )}
      {isReadOnly && (
        <Button
          variant="outlined"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `https://teams.microsoft.com/l/chat/0/0?users=${themeOwner.upn}@uniza.sk,${themeOwner.upn}@fri.uniza.sk,${themeOwner.upn}@stud.uniza.sk&message=${message}`;
          }}
          // component="a"
          // href={`https://teams.microsoft.com/l/chat/0/0?users=${props.theme.upn}@uniza.sk,${props.theme.upn}@fri.uniza.sk,${props.theme.upn}@stud.uniza.sk&message=${message}`}
          style={{ margin: "1em" }}
        >
          Mám záujem o tému
        </Button>
      )}
    </Container>
  );
};

export default ThesisDetail;
