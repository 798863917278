import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "./Components/Login";
import { Fragment, useState } from "react";
import Header from "./Components/Header";
import ThemeList from "./Components/ThemeList";
import ThesisDetail from "./Components/ThesisDetail";
import { Typography } from "@mui/material";

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const [themeList, setThemeList] = useState("");

  const [theme, setTheme] = useState(null);
  const [themeOwnerId, setThemeOwnerId] = useState(null);

  const onLoginHandler = (u, t) => {
    setUser(u);
    setToken(t);
  };

  const showThemeDetails = (thesis, appuser_id) => {
    setTheme(thesis);
    setThemeOwnerId(appuser_id);
  };

  const backHandler = () => {
    setTheme(null);
    setThemeOwnerId(null);
  };

  const setLoginPage = () => {
    setTheme(null);
    setThemeOwnerId(null);
  };

  const showMyThemes = () => {
    backHandler();
    setThemeList("myThemes");
    navigate("/");
  };

  const showAllThemes = () => {
    backHandler();
    setThemeList("");
    navigate("/");
  };

  const addTheme = () => {
    setTheme(null);
    setThemeOwnerId(null);
    navigate("/themeDetail");
  };

  return (
    <Fragment>
      <Header
        user={user}
        setUser={setUser}
        backHandler={backHandler}
        loginPage={setLoginPage}
        showMyThemes={showMyThemes}
        showAllThemes={showAllThemes}
        addTheme={addTheme}
      />
      <Routes>
        <Route
          path="/"
          element={
            <ThemeList
              showThemeDetails={showThemeDetails}
              themeList={themeList}
              user={user}
              token={token}
            />
          }
        />
        <Route
          path="/themeDetail"
          element={
            <ThesisDetail
              thesis_id={theme ? theme.id : null}
              backHandler={backHandler}
              user={user}
              token={token}
              themeOwnerId={themeOwnerId}
              theme={theme}
            />
          }
        />
        {user && token && <Route path="/login" element={<Navigate to="/" />} />}
        {(!user || !token) && (
          <Route path="/login" element={<Login onLogin={onLoginHandler} />} />
        )}

        {/* {page === "/" && (
          <ThemeList
            showThemeDetails={showThemeDetails}
            themeList={themeList}
            user={user}
            token={token}
          />
        )}
        {page === "/themeDetail" && (
          <ThesisDetail
            thesis_id={theme ? theme.id : null}
            backHandler={backHandler}
            user={user}
            token={token}
            themeOwnerId={themeOwnerId}
            theme={theme}
          />
        )}
        {page === "/login" && <Login onLogin={onLoginHandler} />} */}
      </Routes>
    </Fragment>
  );
}

export default App;
