import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  RadioGroup,
  Typography,
  useThemeProps,
} from "@mui/material";
import { Fragment, useState } from "react";

const ThemeFilter = (props) => {
  const [showFilter, setShowFilter] = useState(props.showFilter);

  const [bc, setBc] = useState(props.stupenFilter.includes("bakalár"));
  const [ing, setIng] = useState(props.stupenFilter.includes("inžinier"));
  const [phd, setPhD] = useState(props.stupenFilter.includes("doktorand"));

  const onNameFilterChanged = (e) => {
    props.setNameFilter(e.target.value);
  };

  const onAuthorFilterChanged = (e) => {
    props.setAuthorFilter(e.target.value);
  };

  const onStupenChanged = (stupen) => {
    var actualBc = bc,
      actualIng = ing,
      actualPhD = phd;
    if (stupen === "bc") {
      actualBc = !actualBc;
      setBc(actualBc);
    }
    if (stupen === "ing") {
      actualIng = !actualIng;
      setIng(actualIng);
    }
    if (stupen === "phd") {
      actualPhD = !actualPhD;
      setPhD(actualPhD);
    }
    props.setStupenFilter(
      `${actualBc ? "bakalár" : ""}${actualIng ? "inžinier" : ""}${
        actualPhD ? "doktorand" : ""
      }`
    );
  };

  const onShowFilterChanged = () => {
    setShowFilter((value) => !value);
  };

  return (
    <Fragment>
      <Typography
        variant="h5"
        component="div"
        onClick={onShowFilterChanged}
        style={{ cursor: "pointer" }}
      >
        Filter {showFilter && "↝"} {!showFilter && "↜"}
      </Typography>
      {showFilter && (
        <Fragment>
          <FormControl style={{ marginBottom: 10 }}>
            <InputLabel htmlFor="nazov">Názov práce</InputLabel>
            <Input
              id="nazov"
              type="text"
              required={false}
              value={props.nameFilter}
              onChange={onNameFilterChanged}
            />
          </FormControl>
          <br />
          <FormControl style={{ marginBottom: 10 }}>
            <InputLabel htmlFor="autor">Školiteľ</InputLabel>
            <Input
              id="autor"
              type="text"
              required={false}
              value={props.authorFilter}
              onChange={onAuthorFilterChanged}
            />
          </FormControl>
          <br />
          <FormControl style={{ marginBottom: 10 }}>
            <FormGroup>
              <FormLabel>Stupeň</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bc}
                    onChange={() => onStupenChanged("bc")}
                  />
                }
                label="Bakalár"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ing}
                    onChange={() => onStupenChanged("ing")}
                  />
                }
                label="Inžinier"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={phd}
                    onChange={() => onStupenChanged("phd")}
                  />
                }
                label="Doktorand"
              /> */}
            </FormGroup>
          </FormControl>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ThemeFilter;
