import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Fragment, useState } from "react";

import Image from "./FRI_H_T_sk_biele.png";
import Image2 from "./FRI_V_T_sk_biela-upravena.png";
import { Avatar, Button, Container, Hidden } from "@mui/material";
import { Link } from "react-router-dom";

const buttonStyle = {
  color: "white",
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Open Sans",
};
const menuStyle = {
  paddingRight: 20,
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Open Sans",
};

const Header = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //   const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  //   const handleChange = (event) => {
  //     setAuth(event.target.checked);
  //   };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    props.setUser(null);
    //handleClose();
  };

  const handleLogin = () => {
    handleCloseUserMenu();
    props.loginPage();
  };

  const handleAllThemes = () => {
    props.showAllThemes();
  };

  const handleMyThemes = () => {
    props.showMyThemes();
  };

  const handleAddTheme = () => {
    props.addTheme();
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "#feb81c", marginBottom: "3em" }}
    >
      <Container maxWidth="xm">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={{
                pathname: "/",
              }}
            >
              <img
                src={Image}
                style={{ maxHeight: "6em", width: "100%" }}
                onClick={props.backHandler}
              />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography style={menuStyle} onClick={handleAllThemes}>
                  Všetky témy
                </Typography>
                {props.user && props.user.role === "ucitelia" && (
                  <Typography style={menuStyle} onClick={handleMyThemes}>
                    Moje témy
                  </Typography>
                )}
                {props.user && props.user.role === "ucitelia" && (
                  <Typography style={menuStyle} onClick={handleAddTheme}>
                    Pridaj novú
                  </Typography>
                )}
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="div"
            // component="a"
            // href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={{
                pathname: "/",
              }}
            >
              <img
                src={Image}
                style={{ width: "100%", margin: "auto", padding: "auto" }}
                onClick={props.backHandler}
              />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button style={buttonStyle} onClick={handleAllThemes}>
              Všetky témy
            </Button>
            {props.user && props.user.role === "ucitelia" && (
              <Button style={buttonStyle} onClick={handleMyThemes}>
                Moje témy
              </Button>
            )}
            {props.user && props.user.role === "ucitelia" && (
              <Button style={buttonStyle} onClick={handleAddTheme}>
                Pridaj novú
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {props.user && (
                <Fragment>
                  <Typography
                    variant="h7"
                    component="div"
                    sx={{ flexGrow: 1, color: "white" }}
                  >
                    <Hidden xlDown>{props.user.name}</Hidden>
                  </Typography>
                </Fragment>
              )}
              <AccountCircle
                style={{ color: "white", width: "3em", height: "1.5em" }}
              />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {props.user && (
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Odhlásiť</Typography>
                </MenuItem>
              )}
              {!props.user && (
                <MenuItem onClick={handleLogin}>
                  <Typography textAlign="center">
                    <Link
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={{
                        pathname: "/login",
                      }}
                    >
                      Prihlásiť
                    </Link>
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );

  // return (
  //   <Box sx={{ flexGrow: 1, paddingBottom: 8 }}>
  //     <AppBar position="static" style={{ backgroundColor: "#feb81c" }}>
  //       <Toolbar>
  //         <img
  //           src={Image}
  //           style={{ maxHeight: "7em" }}
  //           onClick={props.backHandler}
  //         />

  //         <Typography
  //           variant="h6"
  //           component="div"
  //           style={{ paddingRight: 20 }}
  //           onClick={props.backHandler}
  //         >
  //           <Button
  //             variant="outlined"
  //             style={{ borderColor: "white", color: "white" }}
  //             onClick={handleAllThemes}
  //           >
  //             Všetky témy
  //           </Button>
  //         </Typography>
  //         {props.user && props.user.role === "ucitelia" && (
  //           <Typography
  //             variant="h6"
  //             component="div"
  //             style={{ paddingRight: 20 }}
  //           >
  //             <Button
  //               variant="outlined"
  //               style={{ borderColor: "white", color: "white" }}
  //               onClick={handleMyThemes}
  //             >
  //               Moje témy
  //             </Button>
  //           </Typography>
  //         )}
  //         {props.user && props.user.role === "ucitelia" && (
  //           <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
  //             <Button
  //               variant="outlined"
  //               style={{ borderColor: "white", color: "white" }}
  //             >
  //               Pridaj novú
  //             </Button>
  //           </Typography>
  //         )}
  //         <Typography
  //           variant="h6"
  //           component="div"
  //           sx={{ flexGrow: 1 }}
  //         ></Typography>
  //         <div>
  //           {props.user && (
  //             <Fragment>
  //               <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
  //                 {props.user.name}
  //               </Typography>
  //             </Fragment>
  //           )}
  //           <IconButton
  //             size="large"
  //             aria-label="account of current user"
  //             aria-controls="menu-appbar"
  //             aria-haspopup="true"
  //             onClick={handleMenu}
  //             color="inherit"
  //           >
  //             <AccountCircle />
  //           </IconButton>
  //           <Menu
  //             id="menu-appbar"
  //             anchorEl={anchorEl}
  //             anchorOrigin={{
  //               vertical: "top",
  //               horizontal: "right",
  //             }}
  //             keepMounted
  //             transformOrigin={{
  //               vertical: "top",
  //               horizontal: "right",
  //             }}
  //             open={Boolean(anchorEl)}
  //             onClose={handleClose}
  //           >
  //             {props.user && <MenuItem onClick={handleLogout}>Logout</MenuItem>}
  //             {!props.user && <MenuItem onClick={handleLogin}>Login</MenuItem>}
  //           </Menu>
  //         </div>
  //       </Toolbar>
  //     </AppBar>
  //   </Box>
  // );
};

export default Header;
